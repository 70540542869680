import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/account": [3],
		"/auth": [4],
		"/auth/forgot-password": [5],
		"/auth/login": [6],
		"/auth/logout": [7],
		"/auth/reset-password": [8],
		"/auth/signup": [9],
		"/courses": [10],
		"/courses/[slug]": [11],
		"/courses/[slug]/[slug]": [12],
		"/learning-paths": [13],
		"/learning-paths/[slug]": [14],
		"/legal/[slug]": [15],
		"/private": [16],
		"/private/delete-user-anonymize": [18],
		"/private/delete-user": [17],
		"/private/notificationtest": [19],
		"/private/update-profile": [20],
		"/profile": [21],
		"/use-case-marketing": [22]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';