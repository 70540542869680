import * as Sentry from "@sentry/svelte";
import type { HandleClientError } from '@sveltejs/kit';

export const handleError: HandleClientError = ({ error, event }) => {
	if (event.url.toString() === 'http://sveltekit-prerender/0') {
		return {
			message: 'Blocked unknown path 0',
			code: 404,
		};
	}
	Sentry.captureException(error, (scope) => scope.setExtra('event', event));

	console.error('error', error);

	return {
		message: 'Whoops!',
		code: error?.code ?? 'UNKNOWN'
	};
};
